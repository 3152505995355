:root {
  --text-color: #e3e3e3;
  --text-hover-color:#c3cce0;
  --dark-clickable-color: #487aa3;
  --highlight-color: #61dafb;
  --dark-text-color: #0E1428;
}

.App {
  text-align: center;
  background-color: var(--dark-text-color);
  color: var(--text-color);
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #0E1428;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: F7F4F3;
}

.App-link {
  color: var(--highlight-color)
}

a {
  color: var(--dark-clickable-color);
  text-decoration:none;
}

a:hover {
  color: var(--bs-link-hover-color);
  cursor: pointer;
}

a:hover .contact-bubble{
  background-color: var(--text-hover-color);
}

a.anchor {
  display: block;
  position: relative;
  top: -60px;
  visibility: hidden;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.borderless{
  margin:0;
  padding:0;
  border:0;
}

.min-w-280{
  min-width: 280px;
}

.min-w-230{
  min-width: 230px;
}

.vertical-divider{
  background-color:var(--dark-clickable-color);
  width:2px;
  margin-top:10vh;
  height:45%;
  margin-right:2.5vw;
}

/* Vertical centering for text */
.rough-centering{
  margin-top:35vh;
}

.text-outer {
  height: 100%;
  display:flex;
  align-items:center;
}

.center-flex{
  justify-content:center;
}

.text-outer:before {
  content: '';
  display: inline-block;
  position: relative;
  height: 100%;
  vertical-align: middle;
  margin-top:-0.25em;
}

.text-inner-homepage {
  display: inline-block;
  margin-top:10vh;
}

.text-inner {
  display: inline-block;
}

/* Navbar */
.navbar-sticky{
  position:fixed;
  z-index:2;
}

.logo-outer-container {
  min-width:145px;
  background-color: var(--text-color);
}

.logo-outer {
  height:100%;
  min-width:145px;
  justify-content: left;
  text-align:center;
}

.logo-outer:before {
  content: '';
  display: inline-block;
  position: relative;
  height: 100%;
  vertical-align: middle;
  margin-top:-0.25em;
}

.logo-inner {
  display: inline-block;
  vertical-align: middle;
}

.logo-text{
  font-weight: bold;
  text-decoration: none;
  font-size: larger;
  white-space: nowrap;
  color:var(--dark-clickable-color);
}

.navbar {
  background-color: var(--text-color);
  justify-content: right;
}

.nav-link{
  color: var(--dark-clickable-color);
  font-weight: bold;
}

.nav-item{
  color: var(--dark-clickable-color); 
  font-weight: bold;
}

.nav-dropdown{
  color: var(--dark-clickable-color); 
}

/* Homepage */
.homepage-intro-box{
  height:calc(100vh - 20px);
}

.left-align{
  text-align: left;
}

.large-font{
  font-size: large;
}

.emphasis{
  color:var(--highlight-color)
}

.homepage-format{
  padding-left: 15vw;
  padding-right: 15vw;
}

.homepage-divider{
  background-color:var(--dark-clickable-color);
  height:2px;
}

/* Socials */

.socials{
  position:fixed;
  bottom:15px;
  right:15px;
}

.socials-bubble{
  height: 35px;
  width: 35px;
  background-color: var(--text-color);
  border-radius: 50%;
  z-index:2;
  margin:15px;
}

.socials-bubble:hover{
  background-color: var(--text-hover-color);
}

.contact-bubble{
  height: 50px;
  width: 50px;
  background-color: var(--text-color);
  border-radius: 50%;
  z-index:2;
  margin:15px;
  float:left;
}

.socials-container{
  overflow:auto;
  display:flex;
  align-items: center;
}

/* Timeline */
.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 80px 0px 40px 0px;
  z-index:1;
}

.timeline-container::after { /*timeline line*/
  background-color: var(--dark-clickable-color);
  content: '';
  position: absolute;
  left: -20px;
  width: 4px;
  height: 100%;
}

.timeline-title{
  text-align:left;
}

.timeline-circle{
  position:absolute;
  left: -25.5px;
  top: calc(50% - 7.5px);
  height: 15px;
  width: 15px;
  background-color: var(--dark-clickable-color);
  border-radius: 50%;
  z-index:2;
}

.timeline-circle-date{
  position:absolute;
  left: -25.5px;
  top: calc(50% - 7.5px);
  height: 15px;
  width: 15px;
  background-color: var(--text-color);
  border-radius: 50%;
  z-index:2;
}

.timeline-link:hover{
  background-color: var(--text-hover-color);
  cursor: pointer;
}

.timeline-item{
  background-color: var(--text-color);
  position:relative;
  float:left;
  margin: 25px 0px 25px 0px;
  border-radius: 5px;
  min-width: 185px;
}

.timeline-text{
  text-align: left;
  color:var(--dark-text-color);
  padding-left:20px;
  padding-right:40px;
  white-space: pre-wrap;
  /* margin: 0px 20px 20px 20px; */
}

.timeline-tag{
  display:inline-block;
  /* float:left; */
  width: max-content;
  border-radius:5px;
  margin-left: 10px;
  margin-right:20px;
}

.timeline-tag-text{
  font-size: smaller;
  margin-right:10px;
}

.timeline-date{
  font-size:smaller;
  width: max-content;
  display:inline-block;
  color:var(--dark-text-color);
  /* float:left; */
}

.timeline-header{
  margin-top: 20px;
  margin-left: 10px;
}

.timeline-date-heading{
  text-align:left;
  font-size:x-large;
  padding-left:20px;
  position:relative;
}

/* Sliding drawer */
.side-drawer { 
   height: 100%;
   background: white;
   position: fixed;
   top: 0;
   right: 0;
   width: 55%;
   z-index: 200;
   box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
   transform: translateX(100%);
   transition: transform 0.3s ease-out;
}
.side-drawer.open {
   transform: translateX(0);
}

/* Backdrop */
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 100;
  top: 0;
  right: 0;
}

.resume-viewer-style{
  height:100vh;
}

.pg-viewer-wrapper{
  overflow-y:unset !important;
}
